<template>
    <div class="home">
        <input type="text" />
        <div v-for="(i,index) in specs" :key="i.name">
            <div>{{i.name}}</div>        
            <button :class="{red:j==i.active}" :disabled="v.disabled" @click="onActive(i.name, v.val, index, j)" v-for="(v, j) in i.value" :key="v.val">{{v.val}}</button>
        </div>
        <!-- <img alt="Vue logo" src="../assets/logo.png" />
        <HelloWorld msg="Welcome to Your Vue.js App" /> -->
        <keep-alive>
        <router-view />
        </keep-alive>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import axios from 'axios'
export default {
    name: "Home",
    data(){
        return {
            specs:[	// 显示数据
                {name: "码数", value: [{val:"小码", disabled:false}, {val:"大码", disabled:false}], active:null},
                {name: "颜色", value: [{val:"红", disabled:false}, {val:"黑", disabled:false}, {val:"蓝", disabled:false}], active:null},
                {name: "尺寸", value: [{val:"S", disabled:false}, {val:"M", disabled:false}, {val:"L", disabled:false}], active:null},        
            ],    
            options:[	// 所有规格数据  临时库存为0的数据
                
                {"颜色": "红", "尺寸": "S", "码数": "小码",id:1},
                {"颜色": "红", "尺寸": "L", "码数": "小码",id:2},
                {"颜色": "蓝", "尺寸": "L", "码数": "小码",id:3}
                // {inventory: 0,spec:{颜色: "红", 尺寸: "S", 码数: "小码"}},
                // {inventory: 2,spec:{颜色: "黑", 尺寸: "S", 码数: "小码"}},
                // {inventory: 3,spec:{颜色: "红", 尺寸: "M", 码数: "小码"}},
                // {inventory: 2,spec:{颜色: "黑", 尺寸: "M", 码数: "小码"}},
                // {inventory: 0,spec:{颜色: "红", 尺寸: "L", 码数: "小码"}},
                // {inventory: 2,spec:{颜色: "黑", 尺寸: "L", 码数: "小码"}},
                // {inventory: 2,spec:{颜色: "蓝", 尺寸: "S", 码数: "小码"}},
                // {inventory: 2,spec:{颜色: "蓝", 尺寸: "M", 码数: "小码"}},
                // {inventory: 0,spec:{颜色: "蓝", 尺寸: "L", 码数: "小码"}},
                // {inventory: 11,spec:{颜色: "红", 尺寸: "S", 码数: "大码"}}
            ],
            seletedOptions:{},		// 存放已选择规格{颜色:红,尺寸:M,码数:小码}
            /**
             * 红：{"颜色": "红", "尺寸": "S", "码数": "小码"},{"颜色": "红", "尺寸": "L", "码数": "小码"}
               M: 
               小码：{"颜色": "红", "尺寸": "L", "码数": "小码"},{"颜色": "蓝", "尺寸": "L", "码数": "小码"}

             */
            noInventory:[],		// 存放0库存的对象 [{颜色:红},...]
            curName: null,		// 当前点击的规格名如：curName:码数
        }
    },
    components: {
        HelloWorld,
    },
    mounted(){
        this.$store.dispatch('test').then(res => {
        })
        //axios.get('/getArticleList')
    },
    methods: {
        resetFn(sName){  // 数据初始化，回归到都是可选状态
            this.specs.forEach(i => {
                if(i.name != sName){
                    i.value.forEach(j => {
                        j.disabled = false
                    })
                }
            })
        },
        onActive(specName, specVal, specNameIndex, specValIndex){
            // this.curName = specName
            this.resetFn(specName)      // 初始状态都可选的

            if(this.seletedOptions[specName] == specVal){
                this.$delete(this.seletedOptions, specName)
            }else{
                this.seletedOptions[specName] = specVal
            }
            
            let noStock = []
            for(let k in this.seletedOptions){      // 已选规格，去匹配 0库存的数据
                let v = this.seletedOptions[k]
                noStock = [...noStock, ...this.getNoStock(k, v)]
            }
            
            let arr = this.specs.map(j => j.value)
            arr.forEach(value => {
                if(value){
                    value.forEach(o => {
                        let a = [].concat(...noStock.map(obj => {
                            let aV = []
                            for(let k in obj){
                                aV.push(obj[k])
                            }
                            return aV;
                        }))
                        if(a.includes(o.val)){
                            o.disabled = true;
                        }
                    })
                }
            })

            if(this.specs[specNameIndex].active == specValIndex){
                this.$set(this.specs[specNameIndex], 'active', null)
            }else{
                this.specs[specNameIndex].active = specValIndex
            }
        },
        getNoStock(specName, specVal){
            let options = JSON.parse(JSON.stringify(this.options))
            let arr = options.filter(i => (specName in i) && (i[specName] == specVal))
            let res = arr.map(i => {
                if(i[specName]){
                    delete i[specName]
                }
                return i;
            })
            return res;
        }
    }
}
</script>

<style scoped>
.red{
    background: red;
    color: #fff;
}
</style>
