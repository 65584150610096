<template>
    <div>
        <div @click="$router.push('/about/subAbout')">to subAbout</div>
        subHome<input type="text" />
    </div>
</template>

<script>
export default {
    name: "subHome"
}
</script>