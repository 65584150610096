import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import subHome from "../views/subHome.vue";
import subAbout from "../views/subAbout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children:[
      {
        path: "subHome",
        component: subHome,
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    children:[
      {
        path: "subAbout",
        component: subAbout,
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
