<template>
    <div>
        <div @click="$router.push('/subHome')">to subHome</div>
        subAbout: <input type="text" />
    </div>
</template>

<script>
export default {
    name: "subAbout",
}
</script>
